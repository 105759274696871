import React from "react";
import BestHomeCare from "../Components/BestHomeCare";
import PageBanner from "../Components/PageBanner";
import BookAppointment from "../Components/BookAppointment";
import Callnow from "../Components/Callnow";
import Servicelist from "../Components/Servicelist";

function Caregivermedical() {
  return (
    <>
      <PageBanner
        name="Care Giver Medical"
        image="assets/images/services/medical/banner.jpg"
      />
      <section className="service-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <Servicelist />
            </div>
            <div className="col-lg-8">
              <div className="service-details__main">
                <div className="service-details__image">
                  <img
                    src="assets/images/services/medical/main.jpg"
                    className="img-fluid"
                    alt=""
                  ></img>
                </div>
                <div className="service-details__content">
                  <h3>Care Giver Medical</h3>
                  <p>
                    Elderly home care plays a crucial role in supporting the
                    well-being of seniors, allowing them to age with dignity and
                    comfort in their own homes, and also provide valuable
                    support to their families.<br></br>
                    We are providing Professionals to support Elders at home
                    with host of medical services listed below{" "}
                  </p>
                  <p>
                    <h4>1. Skilled Nursing Care</h4>
                    Skilled care includes wound care, physical therapy, IV
                    medication administration, and more
                  </p>
                  <p>
                    <h4>2. Physical Therapy</h4>
                    Aims to ease pain, enhance mobility, and improve overall
                    function.
                  </p>
                  <p>
                    <h4>3. Wound Care</h4>
                    Helping in healing and preventing infection.
                  </p>
                  <p>
                    <h4>4. Medication Management</h4>
                    Ensuring that prescribed medications are administered on
                    time.
                  </p>
                  <p>
                    <h4>5. Intravenous Therapy</h4>
                    Administering of fluids, medications, or nutrients directly
                    into a person’s vein.
                  </p>
                  <p>
                    <h4>6. Respiratory Care</h4>
                    Helping individual with breathing and airway problems.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Callnow />

      {/* <BookAppointment /> */}
      {/* <h1 className="text-center">Coming soon</h1> */}
      {/* <BestHomeCare /> */}
    </>
  );
}

export default Caregivermedical;
