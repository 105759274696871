import React from "react";
import BestHomeCare from "../Components/BestHomeCare";
import Numbering from "../Components/Numbering";
import WhymostPeople from "../Components/WhymostPeople";
import PageBanner from "../Components/PageBanner";

function Aboutus() {
  return (
    <>
      <PageBanner
        name="About Us"
        image="assets/images/services/legalservice/banner.jpg"
      />
      <BestHomeCare />
      {/* <Numbering /> */}

      <WhymostPeople />
    </>
  );
}

export default Aboutus;
