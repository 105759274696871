import React from "react";
import PageBanner from "../Components/PageBanner";
import Callnow from "../Components/Callnow";
import Servicelist from "../Components/Servicelist";
function Oldagehome() {
  return (
    <div>
      <PageBanner
        name=" Old Age Home "
        image="assets/images/services/Oldage/Banner.jpg"
      />
      <h1 className="text-center">Coming soon</h1>
      <section className="service-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <Servicelist />
            </div>
            <div className="col-lg-8">
              <div className="service-details__main">
                <div className="service-details__image">
                  <img
                    src="assets/images/services/Oldage/Banner.jpg"
                    className="img-fluid"
                    alt=""
                  ></img>
                </div>
                <div className="service-details__content"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Callnow />
    </div>
  );
}

export default Oldagehome;
