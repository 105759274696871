import React from "react";
import PageBanner from "../Components/PageBanner";
import Callnow from "../Components/Callnow";
import Servicelist from "../Components/Servicelist";
function Asset() {
  return (
    <div>
      <PageBanner
        name=" Asset Management"
        image="assets/images/services/Asset/Banner.jpg"
      />
      <section className="service-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <Servicelist />
            </div>
            <div className="col-lg-8">
              <div className="service-details__main">
                <div className="service-details__image">
                  <img
                    src="assets/images/services/Asset/Main.jpg"
                    className="img-fluid"
                    alt=""
                  ></img>
                </div>
                <div className="service-details__content">
                  <h3>Asset Management</h3>
                  <p>
                    We have different assets. These can be property as asset,
                    money or other things. <br></br>We can assist in managing
                    these assets
                  </p>
                  <p>
                    For example – we can manage your property. We can rent it
                    out, collect the rent, pay property taxes and also do the
                    routine Maintenace work.
                  </p>
                  <p>
                    Financial assets like bank FD, Mutual funds, Shares etc. We
                    will ensure that KYC are updated and the nominees are there.
                    Plus all other matters like address update, Bank accounts
                    and so on.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Callnow />
    </div>
  );
}

export default Asset;
