import React from "react";
import { Link } from "react-router-dom";
function BookAppointment() {
  return (
    <section
      className="cta-one"
      style={{
        backgroundImage: "url(assets/images/main-slider/slider1.jpg)",
      }}
    >
      <div className="container text-center">
        <h3>Book an Appointment Today!</h3>
        {/* <p>
          Lorem ipsum dolor sit amet consec turadipicing elit sed eiusmod
          tempor. <br></br> incididunt labore.dolore. magna aliqua enim ad minim
          veniam.{" "}
        </p> */}
        <br></br>
        <br></br>
        <Link to="/contact" className="thm-btn cta-one__btn">
          {" "}
          Get Appointment{" "}
        </Link>

        {/* <a href="contact.html" className="thm-btn cta-one__btn">
           Appointment
        </a> */}
      </div>
    </section>
  );
}

export default BookAppointment;
