import React from "react";
import PageBanner from "../Components/PageBanner";
import { NavLink } from "react-router-dom";
function Career() {
  return (
    <>
      <PageBanner
        name="Career"
        image="assets/images/services/legalservice/banner.jpg"
      />

      <section className="contact-info-one">
        <div className="container">
          <div className="block-title text-center">
            <p>We're Hiring !</p>
            <h3>Be Part of Our Mission </h3>
            <br />
            <p
              style={{
                color: "#93959e",
                fontWeight: "normal",
                lineHeight: "1.5",
              }}
            >
              We're looking for passionate people to join us on our mission. We
              value flat hierarchies, clear communication, and full ownership
              and responsibility.
            </p>
          </div>
          <div className="row">
            <div className="col-lg-12 wow fadeInUp" data-wow-duration="1500ms">
              <div
                className=" career contact-info-one__single  text-left p-4"
                style={{ backgroundImage: "none" }}
              >
                <h3>Care Giver / कयरगिवर</h3>
                <hr />
                <p>
                  <b>Responsibilities:</b> <br />
                  Assist elderly in daily routines such as bathing diapering and
                  feeding Monitor and record elderly vitals Ensure elderly
                  comfort and hygiene Help with patient mobility and
                  transportation Support medical and administrative tasks as
                  needed <br />
                  <br />
                  <b>Qualifications: </b>
                  <br />
                  Certified GDA or equivalent experience Compassionate and
                  patient-centered approach
                </p>
                <br />

                <p>
                  <b>जिम्मेदारियाँ : </b> <br />
                  वरिष्ठ नागरिकों को स्नान, डायपर बदलने और भोजन कराने जैसी दैनिक
                  दिनचर्याओं में सहायता करना। उनके महत्वपूर्ण संकेतों की निगरानी
                  और रिकॉर्डिंग करना। उनका आराम और स्वच्छता को सुनिश्चित करना
                  उनकी गतिशीलता और परिवहन में सहायता करना। आवश्यकतानुसार
                  चिकित्सा और प्रशासनिक कार्यों में सहायता करना।
                  <br />
                  <br />
                  <b>योग्यता: </b>
                  <br />
                  प्रमाणित GDA या समकक्ष अनुभव करुणामय और रोगी-केंद्रित
                  दृष्टिकोण
                </p>
                <br />
                <a href="/contact" className="thm-btn main-nav-one__btn">
                  Apply Now <i className="far fa-long-arrow-alt-right"></i>
                </a>
                <br />

                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="contact-one">
        <div class="container">
          <div class="block-title text-center">
            <p>Fill form</p>
            <h3>Book an Appointment</h3>
          </div>
          <form
            action="http://html.tonatheme.com/2021/oberlin/assets/inc/sendemail.php"
            class="contact-one__form contact-form-validated"
          >
            <div class="row">
              <div class="col-md-6">
                <input type="text" placeholder="Full Name" name="name"></input>
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  placeholder="Email Address"
                  name="email"
                ></input>
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  placeholder="Phone Number"
                  name="phone"
                ></input>
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  placeholder="Appoinment for"
                  name="appoinment for"
                ></input>
              </div>

              <div class="col-md-12">
                <textarea name="message" placeholder="Message"></textarea>
              </div>
              <div class="col-md-12 text-center">
                <button type="submit" class="thm-btn contact-one__btn">
                  Submit Now
                </button>
              </div>
            </div>
          </form>
          <div class="result"></div>
        </div>
      </section>
    </>
  );
}

export default Career;
