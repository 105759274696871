import React from "react";
import BestHomeCare from "../Components/BestHomeCare";
import PageBanner from "../Components/PageBanner";
import Callnow from "../Components/Callnow";
import Servicelist from "../Components/Servicelist";
function Companion() {
  return (
    <>
      <PageBanner
        name="Companion"
        image="assets/images/services/Companion/banner.jpg"
      />
      <section className="service-details">
        <h1 className="text-center">Coming soon</h1>
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <Servicelist />
            </div>
            <div className="col-lg-8">
              <div className="service-details__main">
                <div className="service-details__image">
                  <img
                    src="assets/images/services/Companion/main.jpg"
                    className="img-fluid"
                    alt=""
                  ></img>
                </div>
                <div className="service-details__content"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Callnow />

      {/* <BestHomeCare /> */}
    </>
  );
}

export default Companion;
