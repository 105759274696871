import React from "react";
import WhymostPeople from "../Components/WhymostPeople";
import NextEvents from "../Components/NextEvents";
import Testimonials from "../Components/Testinomials";
import PageBanner from "../Components/PageBanner";

function OurTeam() {
  return (
    <>
      <PageBanner
        name="Our Team"
        image="assets/images/services/legalservice/banner.jpg"
      />

      <section className="team-one">
        <div className="container">
          <div className="block-title text-center">
            <p>Our Experts</p>
            <h3>Our Skilled Professionals</h3>
          </div>
          <div className="row high-gutter">
            <div className="col-lg-4 wow fadeInLeft" data-wow-duration="1500ms">
              <div className="team-one__single">
                <div className="team-one__image">
                  <img src="assets/images/team/team-1-1.jpg" alt="" />
                </div>
                <div className="team-one__content">
                  <div className="team-one__content-inner">
                    <h3>S. K. Agarwal</h3>
                    <p>Founder</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-8 wow fadeInRight"
              data-wow-duration="1500ms"
            >
              <p className="text-justify">
                <b>S. K. Agarwal</b> who holds a degree in Mechanical
                Engineering from IIT Kanpur and a Diploma in Management from
                Thunderbird USA has over 45 years of Corporate experience in
                manufacturing operations and has been a Business Head in
                different countries other than India viz Japan, Germany and
                Tanzania.
              </p>
              <p>
                He started his career as a Production Engineer from BHEL
                Haridwar and rose to handle top positions in various
                multinationals and top tier Indian companies. He has been
                President of Minda Industries-pan India and VP and Country Head,
                Japan for L&T Ltd. among others. He has been a guest faculty in
                many institutions of repute like IIT, Goenka Tech & MSU Baroda.
                He has also worked as a consultant in diverse industries like
                Handicrafts, Food, Auto, Furniture, Garments & Fabric, Security
                Printing, Auto components etc. He is also a Registered Valuer
                with IBBI as well as a Chartered Engineer for India & Canada and
                is also on the Board of AOTS Alumni Association Delhi.
              </p>
              <p>
                {" "}
                Mr. Agarwal is a talented entrepreneur and his organization
                Agrawal N Associates has advised over 50 companies on turnaround
                management.
              </p>
              <div className="team-one__social">
                <a href="#">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="#">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="#">
                  <i className="fab fa-google-plus-g"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-8 wow fadeInLeft" data-wow-duration="1500ms">
              <p className="text-justify">
                <b>Animesh Thakur</b> has worked in the Corporate sector for 30+
                years in senior positions for growing business across a range of
                industries. Proficient in managing P&L while achieving
                sustainable growth in revenue and profits by identifying
                opportunities and creating business alliances. Coupled with a
                successful and long career in sales and marketing he is adept at
                managing cross-functional teams with a proven track record of
                developing people and exceeding performance expectations. Proven
                record of setting up and growing new businesses both for
                established companies and start-ups.
              </p>
              <p>
                He has worked in senior positions in reputed companies like ITC,
                Convergys, HP among others and has a diverse industry and
                functional experience. He has served as a Board Director for
                several companies. Animesh holds and Engineering Degree from IIT
                Kanpur and a PGDM from IIM Bangalore and is also a registered
                valuer with IBBI. He has been a Guest faculty in a number of
                Management Institutes and has been taking 50 Hour Mandatory
                Classes for DLF RVO for P&M from May 2021.
              </p>

              <div className="team-one__social">
                <a href="#">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="#">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="#">
                  <i className="fab fa-google-plus-g"></i>
                </a>
              </div>
            </div>
            <div
              className="col-lg-4 wow fadeInRight"
              data-wow-duration="1500ms"
            >
              <div className="team-one__single">
                <div className="team-one__image">
                  <img src="assets/images/team/team-1-2.jpg" alt="" />
                </div>
                <div className="team-one__content">
                  <div className="team-one__content-inner">
                    <h3>Animesh Thakur</h3>
                    <p>Co Founder</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 wow fadeInLeft" data-wow-duration="1500ms">
              <div className="team-one__single">
                <div className="team-one__image">
                  <img src="assets/images/team/team-1-4.jpg" alt="" />
                </div>
                <div className="team-one__content">
                  <div className="team-one__content-inner">
                    <h3>Vinay Shukla </h3>
                    <p>Co-Founder</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-8 wow fadeInRight"
              data-wow-duration="1500ms"
            >
              <p className="text-justify">
                <b>Vinay Shukla </b> Vinay has a 32+ years’ experience in legal,
                project management, finance, secretarial, compliance, corporate
                governance, and commercial functions including litigation
                management, conflict management, mediation, arbitration and
                conciliation, land acquisition, EPC, BOT, O&M contracts,
                Transaction advisory, corporate restructuring, for various
                Corporates, Institutions, etc. in India and abroad.
              </p>
              <p>
                {" "}
                He is currently Advocate and Principal Consultant at Whitespan
                Advisory, a full-service law firm having pan India offices.
                Vinay holds a B. Com and LLB and is a member of the Bar Council
                of U.P. He is also a Fellow member (FCS) of the ICSI since 1994.
              </p>
              <div className="team-one__social">
                <a href="#">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="#">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="#">
                  <i className="fab fa-google-plus-g"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-8 wow fadeInLeft" data-wow-duration="1500ms">
              <p className="text-justify">
                <b>Ishita Goyal </b> has over 4 years of work experience in the
                field of Environmental Science. She is a B.Tech (Civil), M.Tech
                (Environmental Science & Engineering) and a PGD (Environmental
                Law & Policy). Ishita has worked on several U.K. based Projects
                in the field of Environmental Impact Assessment, Ambient air
                quality impact prediction, hourly meteorological data, analyzing
                air, water and noise quality baseline data etc. She is a team
                player and her quick learning abilities are valuable in catering
                to changing client needs.
              </p>
              <div className="team-one__social">
                <a href="#">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="#">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="#">
                  <i className="fab fa-google-plus-g"></i>
                </a>
              </div>
            </div>
            <div
              className="col-lg-4 wow fadeInRight"
              data-wow-duration="1500ms"
            >
              <div className="team-one__single">
                <div className="team-one__image">
                  <img src="assets/images/team/team-1-3.jpg" alt="" />
                </div>
                <div className="team-one__content">
                  <div className="team-one__content-inner">
                    <h3>Ishita Goyal</h3>
                    <p>Center Head</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 wow fadeInLeft" data-wow-duration="1500ms">
              <div className="team-one__single">
                <div className="team-one__image">
                  <img
                    src="assets/images/team/Rajeev Chauhan Photo.jpg"
                    alt=""
                  />
                </div>
                <div className="team-one__content">
                  <div className="team-one__content-inner">
                    <h3>Rajeev Chauhan </h3>
                    <p>Business Head (Manpower)</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-8 wow fadeInRight"
              data-wow-duration="1500ms"
            >
              <p className="text-justify">
                <b>Rajeev Chauhan </b> is an experienced Operations Leader with
                over 16 years of expertise in managing large teams and driving
                operational efficiency. A graduate of Lucknow University with an
                advanced diploma in Travel & Tourism Management, Rajeev excels
                in overseeing multi-location operations and ensuring seamless
                manpower movement to meet client needs.
              </p>
              <p>
                Rajeev’s leadership is characterized by a strategic approach to
                problem-solving and a strong commitment to continuous
                improvement. His deep industry knowledge and ability to inspire
                teams make him a pivotal part of our organization.
              </p>
              <p>
                Outside of work, Rajeev is passionate about mentoring the next
                generation of professionals and contributing to industry
                discussions through thought leadership.
              </p>
              <div className="team-one__social">
                <a href="#">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="#">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="#">
                  <i className="fab fa-google-plus-g"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default OurTeam;
