import React from 'react'
import { Link } from "react-router-dom";
function Servicelist() {
  return (
    <>
        <div className="row">
                    
                         <div className="service-details__sidebar">
                            <div className="sidebar__single sidebar__category">
                                <h3 className="sidebar__title">Service List</h3>
                                <ul className="sidebar__cat-list">
                                    <li className=''> <h2> <Link to="/care-giver-medical">Care Giver - Medical</Link></h2></li>                                    
                                    <li> <h2> <Link to="/care-giver-non-medical">Care Giver - Non Medical</Link></h2>
                                    </li> 
                                    <li> <h2> <Link to="/care-giver-non-medical">Companion</Link></h2>
                                    </li> 
                                    <li> <h2> <Link to="/income">Income & Other Taxes</Link></h2>
                                    </li>
                                    <li> <h2> <Link to="/legal-service">Legal Services</Link></h2>
                                    </li>
                                    <li> <h2> <Link to="/home-improvement">Home Improvement</Link></h2>
                                    </li>
                                    <li> <h2> <Link to="/equipment">Equipment Rental</Link></h2>
                                    </li>
                                    {/* <li> <h2> <Link to="/mutual-fund">Mutual Fund Investments</Link></h2>
                                    </li> */}
                                    <li> <h2> <Link to="/insurance">Insurance</Link></h2>
                                    </li>
                                    <li> <h2> <Link to="/investment">Investment</Link></h2>
                                    </li>
                                    <li> <h2> <Link to="/asset">Assest Management</Link></h2>
                                    </li>
                                    <li> <h2> <Link to="/oldagehome">Old Age Home - Residential</Link></h2>
                                    </li>
                                    
                                </ul>
                            </div>

                        </div>                   
                   
                </div>
    </>
  )
}

export default Servicelist
