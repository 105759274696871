import React from "react";
import BestHomeCare from "../Components/BestHomeCare";
import PageBanner from "../Components/PageBanner";
import BookAppointment from "../Components/BookAppointment";
import Servicelist from "../Components/Servicelist";
import Callnow from "../Components/Callnow";
function Termsandconditions() {
  return (
    <>
      <PageBanner name="Terms and Conditions" />
      <section className="service-details">
            <div className="container">
                <div className="row">                
                    
                        <div className="service-details__main">
                            
                            <div className="service-details__content">
                            <h3>Terms and Conditions</h3>
                            <br></br>
                            <h5>1. About Swarninayu</h5>                                
                           <p>

Swarninayu provides health professionals (“Professionals”) for in-home personal care (“Service”) for you or another person who is a parent or relative (“Patient”). Swarninayu conducts background and experience verification of these Professionals via an independent background verification company. The Service is provided by these Professionals only and not by Swarninayu. 
</p>
<h5>2. Customer Agreement</h5>
<p>
You, on behalf of the Patient or care taker, request Swarninayu to provide Service at home or any other location agreed upon with Swarninayu. You agree that the Service is advised by a licensed medical practitioner and you undertake the responsibility for the Service and any risks involved. Swarninayu will ensure a high quality of Service but will not be responsible for any Service errors, including criminal activity by Professionals. You understand and agree that the Service offered by Swarninayu, directly or through Professionals/affiliates of Swarninayu, carries a risk of failure and/or adverse effects, possibly resulting in mortality or permanent disability. You understand and agree that Swarninayu does not guarantee any recovery or outcome from the Service offered. You agree to provide a safe environment for Professionals to perform their duties and ensure that they are not harassed, abused, or troubled in any way, physically or emotionally. You authorize Swarninayu to collect the Patient’s data and use/share the same with others (without revealing the Patient’s identity). Swarninayu will not be responsible for the misuse of the Patient’s data by Professionals, affiliates, employees, or any other party. You agree to make payments to Swarninayu as per the terms agreed upon and will not link payments to the outcome of the Service.
</p>
<h5>3. Scheduling Appointments</h5>
<p>
Appointments for Service with Swarninayu can be scheduled through the Swarninayu website or mobile app. Or call to our call centre Your request for Service is only accepted when you receive a booking confirmation from Swarninayu.
</p>
<h5>4. Professionals Selection</h5>
<p>
Swarninayu has a pool of Professionals to choose from to provide the Service. Swarninayu will ensure that the Professional assigned is as per your preference, but this may not always be possible. Swarninayu reserves the right to assign any Professional as it deems fit for that Service. Swarninayu does not guarantee that the same Professional will be assigned on all days. Swarninayu can assign any Professional on any day as it deems fit without prior notice.
</p>
<h5>5. General Payment Terms</h5>
<p>
Payments can be made via, payment gateway, or direct bank transfer drawn in favor of MOSKITO IN HEAD(OPC) Private Limited. You agree to make payment as soon as the demand is raised. Swarninayu reserves the right to cancel an ongoing or future appointment if payments are not made on time.
</p>
<h5>6. Intellectual Property Rights</h5>
<p>
All intellectual property used on the website/app by Swarninayu, service providers, or any third party shall remain the property of Swarninayu, the service provider, or any other third party, as the case may be. You agree not to circumvent, disable, or otherwise interfere with security-related features of the website/app or features that prevent or restrict the use or copying of any materials or enforce limitations on the use of the website/app or the materials therein. The Service is protected to the maximum extent permitted by copyright laws, other laws, and international treaties. Content displayed on or through the Services is protected by copyright as a collective work and/or compilation, pursuant to copyright laws. The materials on the website or otherwise may not be modified, copied, reproduced, distributed, republished, downloaded, displayed, sold, compiled, posted, or transmitted in any form or by any means, including but not limited to electronic, mechanical, photocopying, recording, or other means.
</p>
<h5>7. Limitation of Liability</h5>
<p>
In no event shall Swarninayu be liable for any special, incidental, indirect, or consequential damages of any kind in connection with these terms of use, even if the user has been informed in advance of the possibility of such damages. In no event shall the total aggregate liability of Swarninayu to a user or professional for all damages, losses, and causes of action (whether in contract or tort, including but not limited to negligence or otherwise) arising from this agreement or a user’s use of the website or the services exceed, in the aggregate, INR 1000.00 .
</p>
<h5>8. Information You Provide to Swarninayu</h5>
<p>
When you request a Service from Swarninayu, you agree to provide personal information about yourself and the Patient, such as name, mobile number, date of birth, gender, weight, and medical information about the Patient relevant to the Service. You agree to provide accurate personal and medical information about the Patient, and your failure to do so may result in adverse consequences in the delivery of Service. Swarninayu may provide this information to assigned Professionals as it deems fit.
</p>
<h5>9. Customized Service and Payment Flow</h5>
<p>
Every patient is different. At Swarninayu, upon receiving your request, we have a detailed conversation with you about your specific medical condition, and then suggest the best course of action. We ask for a payment only when you agree with our proposed plan of action as well as the costs involved. We can request online payments through SMS, email, website, or our mobile app, which also clarifies the details of your booking. We use third-party payment service providers to make it easy for you to pay. Except in urgent situations, we commence the service only upon receiving the payment.
</p>
<h5>10. Communications from Swarninayu
</h5>
<p>
You agree on behalf of yourself and the Patient to receive communications in the form of SMS messages, email, and calls on your mobile number and email ID from both Swarninayu and the Professionals assigned to you. You agree to receive promotional messages in the form of newsletters, special offers, reminders, and updates.
</p>
<h5>11. Termination</h5>
<p>
Swarninayu may immediately terminate the Service at any time for any reason, through notice sent by post to the registered postal address or email communication to the registered email address.
</p>
<h5>12. Choice of Law</h5>
<p>These Terms of Use shall be governed by and interpreted and construed in accordance with the laws of India. The place of jurisdiction shall be exclusively in Gurugram.
</p>
</div>
    </div>
       </div>
            </div>
        </section>
        <Callnow />
    </>
  );
}

export default Termsandconditions;
