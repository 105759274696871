import React from "react";
import BestHomeCare from "../Components/BestHomeCare";
import PageBanner from "../Components/PageBanner";
import BookAppointment from "../Components/BookAppointment";
import Servicelist from "../Components/Servicelist";
import Callnow from "../Components/Callnow";
function Income() {
  return (
    <>
      <PageBanner
        name="Income & Other Taxes"
        image="assets/images/services/income-taxes/banner.jpg"
      />
      <section className="service-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <Servicelist />
            </div>
            <div className="col-lg-8">
              <div className="service-details__main">
                <div className="service-details__image">
                  <img
                    src="assets/images/services/income-taxes/Main-img.jpg"
                    className="img-fluid"
                    alt=""
                  ></img>
                </div>
                <div className="service-details__content">
                  <h3>Income tax advisory and filing services</h3>
                  <p>
                    We have several CA’s and other tax professionals who can
                    help you in tax matters. These can be{" "}
                  </p>
                  <p>
                    <ul>
                      <li>Indian Income Tax</li>
                      <li>International Tax issues.</li>
                      <li>Transfer of money and tax and documents work.</li>
                      <li>
                        Inheritance planning and financial issues related the
                        same.
                      </li>
                      <li>
                        Property sale / purchase and tax and money related to
                        the same.
                      </li>
                      <li>
                        Any other matter relation to government with respect to
                        any type of tax or related matter.
                      </li>
                    </ul>
                  </p>
                  <p>
                    Our CA’s are Mr Ajay Bansal, who has over 30 year experience
                    of auditing, tax matters. He has a team of over 15 people
                    and has excellent experience of handling tax and compliance
                    matters.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Callnow />
    </>
  );
}

export default Income;
