import React from "react";

function Callnow() {
  return (
    <>
      <section className="cta-two">
        <img
          src="assets/images/shapes/cta-1-1-shape-1.png"
          className="cta-two__dot-1"
          alt=""
        ></img>
        <img
          src="assets/images/shapes/cta-1-1-shape-2.png"
          className="cta-two__dot-2"
          alt=""
        ></img>
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-lg-6 d-flex justify-content-end  wow fadeInLeft"
              data-wow-duration="1500ms"
            >
              <div
                className="cta-two__content"
                style={{
                  zIndex: "999999",
                }}
              >
                <h3
                  style={{
                    display: "block",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Call Us Now !
                </h3>
                <br></br> <br></br>
                {/* <div className='thm-btn cta-two__btn'>
                                <p>+91 9811799499</p>
                            </div> */}
                <a className="thm-btn cta-two__btn">+91 9266829800</a>
              </div>

              <div className="cta-two__image">
                <img src="assets/images/resources/cta-1-1.jpg" alt=""></img>
                <div className="cta-two__image-inner">
                  <div className="cta-two__image-content">
                    <h3>
                      <span className="counter">90 %</span>
                    </h3>
                    <p>Success Rate</p>
                  </div>
                </div>
              </div>
            </div>
            {/* 
            <div
              className="col-lg-4  d-flex justify-content-center align-items-start wow fadeInUp"
              data-wow-duration="1500ms"
            >
              <div className="cta-two__content">
                <h3>Call Us Now !</h3>
                <br></br>
                
                <a className="thm-btn cta-two__btn">+91 9266829800</a>
              </div>
              <br />
            </div> */}

            <div
              className="col-lg-6  d-flex justify-content-center align-items-center wow fadeInRight"
              data-wow-duration="1500ms"
            >
              <div className="cta-two__content">
                <h3
                  style={{
                    display: "block",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Pay With QR
                </h3>

                <br></br>
                <img
                  src="assets/images/QR-Code/upi.jpg"
                  alt=""
                  height="400"
                  width="auto"
                ></img>
                {/* <img
                  src="assets/images/QR-Code/Axis QR Code .jpeg"
                  alt=""
                  height="400"
                  width="auto"
                ></img> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Callnow;
