import React from "react";
import BestHomeCare from "../Components/BestHomeCare";
import PageBanner from "../Components/PageBanner";
import Callnow from "../Components/Callnow";
import Servicelist from "../Components/Servicelist";
function Homeimprovement() {
  return (
    <>
      <PageBanner
        name=" Home Improvement"
        image="assets/images/services/Home-improvement/banner.jpg"
      />
      <section className="service-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <Servicelist />
            </div>
            <div className="col-lg-8">
              <div className="service-details__main">
                <div className="service-details__image">
                  <img
                    src="assets/images/services/Home-improvement/main.jpg"
                    className="img-fluid"
                    alt=""
                  ></img>
                </div>
                <div className="service-details__content">
                  <h3>Home Improvement</h3>
                  <p>
                    Elderly require special care. A normal residence needs
                    several modifications. These are{" "}
                  </p>
                  <p>
                    <ul>
                      <li>Anti slip floors </li>
                      <li>Special care in bathing and toilet areas.</li>
                      <li>Railings and support fixtures</li>
                      <li>
                        Remote control for light and fans and other gadgets
                      </li>
                      <li>Emergency alarm systems.</li>
                      <li>Lift to transfer from one floor to other.</li>
                      <li>Any other special requirement that may be there.</li>
                    </ul>
                  </p>
                  <p>
                    We have several tie-ups who are specialised in these.{" "}
                    <br></br>
                    We will be happy to direct them to you to make the life of
                    our dear ones more comfortable.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Callnow />

      {/* <BestHomeCare /> */}
    </>
  );
}

export default Homeimprovement;
