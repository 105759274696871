import React from "react";
import PageBanner from "../Components/PageBanner";
import BestHomeCare from "../Components/BestHomeCare";
import Callnow from "../Components/Callnow";
import Servicelist from "../Components/Servicelist";
function Mutualfund() {
  return (
    <>
      <PageBanner name=" Mutual Fund" />
      <div className="row">
        <div className="col-xl-4  col-md-4  col-lg-4 ml-5" >
        <Servicelist />
        </div>
        <div className="col-xl-4  col-md-4  col-lg-4">

</div>
 <div className="col-xl-4  col-md-4  col-lg-4">

</div>
      </div>
      <Callnow/>
      {/* <BestHomeCare /> */}
      <h1 className="text-center">Coming soon</h1>
    </>
  );
}
export default Mutualfund;
