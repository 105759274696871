import React from "react";

import PageBanner from "../Components/PageBanner";
import BookAppointment from "../Components/BookAppointment";
import BestHomeCare from "../Components/BestHomeCare";
import Callnow from "../Components/Callnow";
import Servicelist from "../Components/Servicelist";
function Equipment() {
  return (
    <>
      <PageBanner
        name=" Equipment"
        image="assets/images/services/Eqvepment/Banner.jpg"
      />
      <section className="service-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <Servicelist />
            </div>
            <div className="col-lg-8">
              <div className="service-details__main">
                <div className="service-details__image">
                  <img
                    src="assets/images/services/Eqvepment/Main.jpg"
                    className="img-fluid"
                    alt=""
                  ></img>
                </div>
                <div className="service-details__content">
                  <h3>Equipment Rental</h3>
                  <p>
                    Our dear ones may require some special assisting item. Like
                    wheel chai or a special bed or some thing else. To buy these
                    is expensive, unless one has to use them for a long time.{" "}
                    <br></br>We offer many such items for rent.
                  </p>
                  <p>
                    <ul>
                      <li>
                        We have Oxygen Concentrator of 5 lts and 10 lts capacity
                        with us at a special rate of Rs 1690/- per month rental.
                      </li>
                    </ul>
                  </p>
                  <p>
                    More items are getting added. Please call us for any
                    information of advice.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Callnow />
      {/* <h1 className="text-center">Coming soon</h1> */}
      {/* <BestHomeCare />
      <BookAppointment /> */}
    </>
  );
}

export default Equipment;
