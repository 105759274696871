import React, { useEffect } from "react";
import $ from "jquery";

function WhymostPeople() {
  useEffect(() => {
    if ($(".accrodion-grp").length) {
      var accrodionGrp = $(".accrodion-grp");
      accrodionGrp.each(function () {
        var accrodionName = $(this).data("grp-name");
        var Self = $(this);
        var accordion = Self.find(".accrodion");
        Self.addClass(accrodionName);
        Self.find(".accrodion .accrodion-content").hide();
        Self.find(".accrodion.active").find(".accrodion-content").show();
        accordion.each(function () {
          $(this)
            .find(".accrodion-title")
            .on("click", function () {
              if ($(this).parent().hasClass("active") === false) {
                $(".accrodion-grp." + accrodionName)
                  .find(".accrodion")
                  .removeClass("active");
                $(".accrodion-grp." + accrodionName)
                  .find(".accrodion")
                  .find(".accrodion-content")
                  .slideUp();
                $(this).parent().addClass("active");
                $(this).parent().find(".accrodion-content").slideDown();
              }
            });
        });
      });
    }
  }, []);

  return (
    <section className="faq-three">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div
              className="faq-three__image wow fadeInLeft"
              data-wow-duration="1500ms"
            >
              <img
                src="../assets/images/shapes/faq-2-dot.png"
                className="faq-three__image-dots"
                alt=""
              />
              <img src="../assets/images/resources/faq-2-1.jpg" alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="faq-one__block">
              <div className="block-title text-left">
                <p className=""> Why Choose US</p>
                <h3>
                  Why Most of The People <br /> Choose Swarnim Ayu
                </h3>
              </div>
              <p>
              We are the ONLY organization that is a COMPREHENSIVE Support for the elderly. <br></br>
The organization is run by Management professionals – who are IIT + IIM and collectively we have over 100+ years of experience. We have held top positions in organizations like Maruti Udyog, General Motors, L&T, and more. Also having international exposure in Japan, Europe and Africa
Management is very strict in compliance and client relationship.
We also do not run a subscription plan. <br></br>
There are many features of our app – those are top of the end and all is for free.
              </p>
             
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhymostPeople;
