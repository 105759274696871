import React from "react";
import BestHomeCare from "../Components/BestHomeCare";
import PageBanner from "../Components/PageBanner";
import BookAppointment from "../Components/BookAppointment";
import Servicelist from "../Components/Servicelist";
import Callnow from "../Components/Callnow";
function Privacyandpolicy() {
  return (
    <>
      <PageBanner name="Privacy Policy" />
      <section className="service-details">
            <div className="container">
                <div className="row">                
                    
                        <div className="service-details__main">
                            
                            <div className="service-details__content">
                            <h3>PRIVACY POLICY</h3>

<h5> Introduction</h5>
<p>
This Privacy Policy is an electronic document in the form of an electronic contract formed under the Information Technology Act, 2000, and the rules made thereunder, including the amended provisions related to electronic documents and records in various statutes as amended by the Information Technology Act, 2000. This Privacy Policy does not require any physical, electronic, or digital signature. This document is published in compliance with the provisions of the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011, under the Information Technology Act, 2000. The policy is applicable to the collection, use, storage, and transfer of sensitive personal data or information. This Privacy Policy is a legally binding document between you and Swarninayu. By using the website/app, you indicate that you understand, agree, and consent to this Privacy Policy. If you do not agree with the terms of this Privacy Policy, please do not use this website/app. By providing your information or using the services provided by the website/app, you consent to the collection, storage, processing, and transfer of your personal and non-personal information by Swarninayu as specified under this Privacy Policy. 
</p>
<h5>1. COLLECTION AND STORAGE OF INFORMATION</h5>
<p>1.1 The information provided by you to Swarninayu or collected from you by Swarninayu may consist of “Personal Information” and “Non-Personal Information”.
<br></br>
1.2 Personal Information refers to information that can be used to uniquely identify or contact you, such as your name, address, contact number, email address, financial information, or any other information that is classified as “sensitive personal data or information” under the Information Technology Rules, 2011.
<br></br>
1.3 Non-Personal Information includes information that does not personally identify you, such as your geographic location, browser type, and operating system.
<br></br>
1.4 Swarninayu may collect your personal information in various ways, including during registration on the website, participation in surveys, or use of the website’s services.
<br></br>
1.5 We may receive personal information about you from third parties, such as social media services or business partners. By accessing our website through social media services, you authorize Swarninayu to collect, store, use, and retain such information in accordance with this Privacy Policy.
<br></br>
1.6 Swarninayu may also collect information other than personal information through the website when you visit or use the website. This information may include your geographic location, details of your telecom service provider, the type of browser, the operating system of your device, and the last visited website.
<br></br>
1.7 Non-personal information is collected through various means, including cookies. Swarninayu may store temporary or permanent “cookies” on your computer. You can choose to block these cookies, but doing so may prevent you from using certain features of the website.
<br></br>
1.8 You represent that the information you provide is accurate, current, and updated and that you have all the rights to provide such information to Swarninayu.
<br></br>
1.9 Swarninayu is not responsible for the authenticity of the information you or any other user provide to Swarninayu. You shall indemnify Swarninayu and its affiliates for any loss caused due to the inaccurate or misleading information provided by you.
<br></br>
1.10 Your information will primarily be stored in electronic form; however, some data may also be stored in physical form. Swarninayu may store, collect, process, and use your data in countries other than India but will comply with applicable laws.
</p>
<h5>2. PURPOSE FOR COLLECTING, USING, STORING, AND PROCESSING INFORMATION</h5>
<p>Swarninayu collects, uses, stores, and processes your information for lawful purposes, including:
<br></br>
2.1 To facilitate your use of the website/app.
<br></br>
2.2 To respond to your inquiries or fulfill requests for information about services offered.
<br></br>
2.3 To provide information about services available on the website/app and send you information, materials, and offers.
<br></br>
2.4 To send you important information regarding the website/app, changes in terms and conditions, user agreements, and policies.
<br></br>
2.5 To send you surveys and marketing communications.
<br></br>
2.6 To personalize your experience on the website/app by presenting advertisements, services, and offers tailored to your preferences.
<br></br>
2.7 To help address any problems you encounter on the website/app.
<br></br>
2.8 To complete and fulfill the services you avail of on the website/app.
<br></br>
2.9 To protect the integrity of the website/app.
<br></br>
2.10 To conduct internal reviews and data analysis for the website/app.
<br></br>
2.11 To improve the services and content on the website/app.
<br></br>
2.12 To conduct academic research, surveys, and analytical studies on user behavior and preferences.
<br></br>
2.13 To respond to legal processes and provide information to law enforcement agencies.
<br></br>
2.14 To implement information security practices.
<br></br>
2.15 To determine any security breaches or computer viruses.
<br></br>
2.16 To investigate, prevent, or take action regarding illegal activities and suspected fraud.
<br></br>
2.17 To conduct forensics of the concerned computer resource as a part of investigation or internal audit.
<br></br>
2.18 To process any job application you submit.
<br></br>
2.19 To trace computer resources or any person who may have contravened any provision of law, including the Information Technology Act, 2000.
<br></br>
2.20 To enable a potential buyer or investor to evaluate the business of Swarninayu.
</p>
<h5>3. SHARING AND DISCLOSURE OF YOUR INFORMATION</h5>
<p>
3.1 Swarninayu may transfer, share, disclose, or part with your information to third-party service providers, partners, banks, and financial institutions for the purposes mentioned above or as required by applicable law.
<br></br>
3.2 Swarninayu may share statistical data and other details (excluding your personal information) without your express or implied consent to facilitate various programs or initiatives launched by Swarninayu, its affiliates, agents, third-party service providers, partners, or banks and financial institutions.
<br></br>
3.3 Swarninayu may share personal information if necessary to investigate, prevent, or take action regarding illegal activities, suspected fraud, potential threats to physical safety, violations of terms and conditions, or policies.
<br></br>
3.4 Swarninayu reserves the right to disclose your information as required by law or regulation or in response to a request from law enforcement or government agencies.
<br></br>
3.5 You agree that the sharing, transfer, and disclosure of your personal and non-personal information shall not cause any wrongful loss or gain to you or any third party.
<br></br>
3.6 In the event of a merger, acquisition, reorganization, or restructuring of business, Swarninayu may share or sell your personal information to another business entity, which will be required to follow this Privacy Policy.

</p>
<h5>4. LINK TO OTHER WEBSITES</h5>
<p>
4.1 Our website/app may provide links to other websites. These links are for your convenience only, and Swarninayu is not responsible for the content or security of these sites.
<br></br>
4.2 Swarninayu may have certain features on its website hosted by third parties. Your interaction with such features will be governed by the privacy policy of those third parties.
<br></br>
4.3 Swarninayu shall not be responsible for any loss, damage, claim, or expense caused by your access to these third-party sites and features.
</p>
<h5>5. SECURITY</h5>
<p>5.1 Swarninayu strives to ensure the security, integrity, and privacy of your information and adopts reasonable measures to prevent unauthorized access to your personal information.
<br></br>
5.2 Swarninayu reserves the right to conduct a security review at any time to verify your identity. You agree to provide all the information requested for the security review. Failure to comply may result in the termination of your account and access to the website.
<br></br>
5.3 Swarninayu is not liable for any breach of security or actions by third parties that receive your personal information.
<br></br>
5.4 The trademarks, logos, and service marks displayed on the website are the property of Swarninayu or their respective owners. You are not permitted to use these marks without prior consent.
<br></br>
5.5 Swarninayu shall not be held responsible for any loss, damage, or misuse of your personal information caused by a force majeure event, which includes events beyond reasonable control such as sabotage, fire, flood, explosion, acts of God, civil commotion, strikes, riots, war, government actions, computer hacking, unauthorized access, and more.
</p>
<h5>6. CHOICE/OPT-OUT</h5>
<p>You agree and acknowledge that you are providing your information of your free will. You have the option not to provide your personal information or to withdraw your consent by sending an email to the grievance officer or other electronic address provided.
</p>
<h5>7. UPDATES TO THIS PRIVACY POLICY</h5>
<p>Swarninayu may change its data privacy practices and update this Privacy Policy as necessary. The latest version will always be available on the website. </p>
<h5>8. GRIEVANCE OFFICER</h5>
<p>
If you have any grievances regarding this Privacy Policy, please contact our Grievance Officer at the contact information provided on our website.
</p>
</div>
    </div>
       </div>
            </div>
        </section>
        <Callnow />
    </>
  );
}

export default Privacyandpolicy;
