import React from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";
const MultiSlider = () => {
  const options = {
    items: 2,
    nav: false,
    dots: true,
    margin: 40,
    loop: true,
    smartSpeed: 700,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    responsive: {
      0: { items: 1, margin: 0, dots: false, nav: true },
      767: { items: 1, margin: 0, dots: false, nav: true },
      991: { items: 2, margin: 30 },
      1199: { items: 4 },
    },
  };

  return (
    <section className="service-one service-one__home-one">
      <div className="container">
        <div className="block-title text-center">
          <p>Our Services</p>
          <h3>
            Everyone Deserves Our <br /> Best Services
          </h3>
        </div>
        <OwlCarousel
          className="service-one__carousel-two thm__owl-carousel owl-carousel owl-theme"
          {...options}
        >
          <div className="item">
            <div className="service-one__single">
              <div className="service-one__content">
                <div className="service-one__icon">
                  <i className="oberlin-icon-stethoscope"></i>
                </div>
                <h3> <Link to="/care-giver-medical">Care Giver <br></br> Medical</Link></h3>
                <Link to="/care-giver-medical" className="service-one__link">
                <i className="far fa-long-arrow-alt-right"></i>
                
                </Link>               
              </div>
            </div>
          </div>
          
          <div className="item">
            <div className="service-one__single">
              <div className="service-one__content">
                <div className="service-one__icon">
                  <i className="oberlin-icon-disability"></i>
                </div>
                <h3> <Link to="/care-giver-non-medical">Care Giver <br></br> Non Medical</Link></h3>
                <Link to="/care-giver-non-medical" className="service-one__link">
                <i className="far fa-long-arrow-alt-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="service-one__single">
              <div className="service-one__content">
                <div className="service-one__icon">
                <i className="fas fa-user-friends"></i>
                  {/* <i className="oberlin-icon-blind"></i> */}
                </div>
                <h3> <Link to="/companion">Companion <br></br><span>&nbsp;</span></Link></h3>
                <Link to="/companion" className="service-one__link">
                <i className="far fa-long-arrow-alt-right"></i>
                </Link>                
              </div>
            </div>
          </div>

          <div className="item">
            <div className="service-one__single">
              <div className="service-one__content">
                <div className="service-one__icon">
                
                 <i className="fas fa-rupee-sign"></i>
                  {/* <i className="oberlin-icon-stethoscope"></i> */}
                </div>
                <h3> <Link to="/income">Income and <br></br>Other Taxes</Link></h3>
                <Link to="/income" className="service-one__link">
                <i className="far fa-long-arrow-alt-right"></i>
                </Link>
                
              </div>
            </div>
          </div>
          <div className="item">
            <div className="service-one__single">
              <div className="service-one__content">
                <div className="service-one__icon">
                {/* <i className="fas fa-balance-scale"></i> */}
                <i className="fas fa-gavel"></i>
                  {/* <i className="oberlin-icon-disability"></i> */}
                </div>
                <h3> <Link to="/legal-service">Legal Services <br></br><span>&nbsp;</span></Link></h3>
                <Link to="/legal-service" className="service-one__link">
                <i className="far fa-long-arrow-alt-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="service-one__single">
              <div className="service-one__content">
                <div className="service-one__icon">
                <i className="fas fa-tools"></i>
                <i className="fas fa-hammer"></i>
                {/* <img src="assets/images/legalservice-icon/icons8-legal-100.png"></img> */}
                   {/* <i className="oberlin-icon-blind"></i> */}
                </div>
                <h3> <Link to="/home-improvement">Home Improvement <br></br><span>&nbsp;</span></Link></h3>
                <Link to="/home-improvement" className="service-one__link">
                <i className="far fa-long-arrow-alt-right"></i>
                </Link>               
              </div>
            </div>
          </div>
          <div className="item">
            <div className="service-one__single">
              <div className="service-one__content">
                <div className="service-one__icon">
                <i className="fas fa-truck-loading"></i>
                
                  {/* <i className="oberlin-icon-blind"></i> */}
                </div>
                <h3> <Link to="/equipment">Equipment Rental <br></br><span>&nbsp;</span></Link></h3>
                <Link to="/equipment" className="service-one__link">
                <i className="far fa-long-arrow-alt-right"></i>
                </Link>   
              </div>
            </div>
          </div>
          
          <div className="item">
            <div className="service-one__single">
              <div className="service-one__content">
                <div className="service-one__icon">
                {/* <i className="fas fa-shield-alt"></i>
                <i className="fas fa-file-contract"></i> */}
                <i className="fas fa-user-shield"></i>
                </div>
                <h3> <Link to="/insurance">Insurance <br></br><span>&nbsp;</span></Link></h3>
                <Link to="/insurance" className="service-one__link">
                <i className="far fa-long-arrow-alt-right"></i>
                </Link>   
              </div>
            </div>
          </div>
          <div className="item">
            <div className="service-one__single">
              <div className="service-one__content">
                <div className="service-one__icon">
                <i className="fas fa-chart-line"></i>
                </div>
                <h3> <Link to="/investment">Investment <br></br><span>&nbsp;</span></Link></h3>
                <Link to="/investment" className="service-one__link">
                <i className="far fa-long-arrow-alt-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="service-one__single">
              <div className="service-one__content">
                <div className="service-one__icon">
                <i className="fas fa-briefcase"></i>
                <i className="fas fa-coins"></i>
                </div>
                <h3> <Link to="/asset">Assest Management <br></br><span>&nbsp;</span></Link></h3>
                <Link to="/asset" className="service-one__link">
                <i className="far fa-long-arrow-alt-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="service-one__single">
              <div className="service-one__content">
                <div className="service-one__icon">            
                <i className="fas fa-hand-holding-heart"></i>
                </div>
                <h3> <Link to="/oldagehome">Old Age Home <br></br> Residential</Link></h3>
                <Link to="/oldagehome" className="service-one__link">
                <i className="far fa-long-arrow-alt-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </OwlCarousel>
      </div>
    </section>
  );
};

export default MultiSlider;
