import React from "react";
import BestHomeCare from "../Components/BestHomeCare";
import PageBanner from "../Components/PageBanner";
import BookAppointment from "../Components/BookAppointment";
import Servicelist from "../Components/Servicelist";
import Callnow from "../Components/Callnow";
function Paymentandrefundpolicy() {
  return (
    <>
      <PageBanner name="Payment, Refund & Cancellation Policy" />
      <section className="service-details">
            <div className="container">
                <div className="row">                
                    
                        <div className="service-details__main">
                            
                            <div className="service-details__content">
                            <h3>Payment, Refund & Cancellation Policies</h3>
                            <br></br>
                            <h4>1. Payment Policy</h4>
                            <p><h5>Applicable Services</h5>
                           This policy applies to all services provided by Swarninayu, including
                             Attendant, Nursing Care, Critical Care, Infant Care, and Rehab Care, except
                             for Physiotherapy, short-term intervention services, and pathlab testing.
                              This also does not care professional services like lawyer etc.
                              </p><br></br>
<h5>Direct Payment to Swarninayu Only</h5>
<p>Payments should be made directly to Swarninayu and not to the Professionals, unless specifically 
instructed by the Swarninayu Customer Service team. You can pay online through our app, request a 
cash/cheque pickup, or use other payment methods.
</p><br></br>
<h5>Payment Modes Accepted</h5>
<p>Payments can be made through our app, online transfers, NEFT, cheque, or cash. Swarninayu encourages
 and prefers online payments in advance to ensure timely payments to our Professionals. For cash or 
 cheque pickups, the amount must exceed Rs. 25000; any amount below this should be paid online.
 </p>
 <br></br>
<h5>Payment Processing Fee</h5>
<p>A fee of 2.5% of the invoice amount will be charged on all invoices generated.
</p>
<br></br>
<h4>2. Payment Facilities</h4>

<p><h5>Weekly Billing</h5>
An invoice will be generated after each service and sent to the customer via email following successful payment and service delivery.
</p>
<br></br>
<h5>Monthly Billing</h5>
<p>For customers opting for a monthly plan, an advance payment covering 30 days is required. If the advance payment is not made, the default payment cycle will revert to weekly billing.
ALL PAYMENT IS ADVANCE ONLY.</p>
<br></br>
<h5>Surcharge</h5>
<p>
An additional surcharge may be applied during festival seasons, night services, long distance service as an incentive for Professionals to work and support patients during these times. The surcharge details will be communicated two days before any festival and may range from Rs. [amount] to Rs. [amount] per 12-hour visit, depending on the festival.
</p>
<br></br>
<h4>3. General Terms and Conditions on Payments</h4>
<p>Swarninayu will not be responsible for any payments made directly to Professionals. All service fees must be paid directly to Swarninayu, and any payments made to Professionals will not be deducted from the service fees due.
</p>
<p>
The stated amounts include charges only for verified visits by Professionals. Charges for unverified visits will be added after confirmation from both the customer and the Professionals.
</p>
<br></br>
<h4>4. Refund Policy</h4>
<p>
Once a refund is initiated, the balance amount will be credited to your account within 7 working days.
</p>
<p>Refunds will only be processed through NEFT.</p>
<p>Please review our terms and conditions at [www.swarninayu.in](www.swarninayu.in).
</p>
<h4>5. Cancellation Policy</h4>
<p>
You may cancel or reschedule any appointment through our website or mobile app, or a call to our call centre. This has to be done at least 12 hours before the scheduled appointment. If you cancel later than this, a fee of 30% of the service charge will be applied.
</p>
<p>
In the event of a request for rescheduling, Swarninayu cannot guarantee that the same Professional will be available for the new appointment time.
</p>
<p>
Swarninayu reserves the right to cancel or reschedule any appointment without prior notice. In such cases, 100% of the service charge will be refunded, provided there is no discrepancy from the customer's end in the delivery of service.
</p>
<p>If Swarninayu cancels an appointment, any advance payment made by you will be refunded in full.</p>
</div>
    </div>
       </div>
            </div>
        </section>
        <Callnow />
    </>
  );
}

export default Paymentandrefundpolicy;
