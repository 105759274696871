import React, { useRef } from "react";
import OwlCarousel from "react-owl-carousel";

const SliderOne = () => {
  const carouselRef = useRef(null);

  const options = {
    items: 1,
    loop: true,
    autoplay: true,
    autoplayHoverPause: true,
    autoplayTimeout: 5000,
    margin: 0,
    dots: false,
    nav: false, // Disable Owl Carousel's default nav buttons
    animateOut: "fadeOut",
    animateIn: "fadeIn",
    smartSpeed: 1000,
  };

  const handlePrevClick = (e) => {
    e.preventDefault();
    if (carouselRef.current) {
      carouselRef.current.prev();
    }
  };

  const handleNextClick = (e) => {
    e.preventDefault();
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  return (
    <section className="slider-one__wrapper">
      <div className="slider-one__nav">
        <a href="#" className="slider-one__nav-left" onClick={handlePrevClick}>
          <i className="far fa-angle-left"></i>
        </a>
        <a href="#" className="slider-one__nav-right" onClick={handleNextClick}>
          <i className="far fa-angle-right"></i>
        </a>
      </div>
      <OwlCarousel
        ref={carouselRef}
        className="slider-one__carousel owl-carousel owl-theme martop"
        {...options}
      >
        <div
          className="item"
          style={{
            backgroundImage: "url(assets/images/main-slider/slider1.jpg)",
          }}
        >
          <div className="slider-one__item">
            <div className="container">
              <div className="row justify-content-end">
                <div className="col-lg-12 d-flex justify-content-end">
                  <div className="slider-one__content">
                    <div className="slider-one__content-inner">
                      <h3>
                        Your Health Care <br></br>at Home.
                      </h3>
                      <p>
                        Experience high-quality, affordable, and convenient
                        healthcare services right in the comfort of your own
                        home with Swarninayu!
                      </p>
                      <p className="thm-btn slider-one__btn ">
                        Call Now +91 9266829800
                      </p>
                      {/* <a
                        href="donation-single.html"
                        className="thm-btn slider-one__btn"
                      >
                        Donate Now
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="item"
          style={{
            backgroundImage: "url(assets/images/main-slider/slider2.jpg)",
          }}
        >
          <div className="slider-one__item">
            <div className="container">
              <div className="row justify-content-end">
                <div className="col-lg-12 d-flex justify-content-end">
                  <div className="slider-one__content">
                    <div className="slider-one__content-inner">
                      <h3>
                        We're Here <br></br> for You
                      </h3>
                      <p>
                        If you need a nursing professional to care for your
                        loved ones at home, simply reach out through our contact
                        section or email us at support@swarninayu.in for a
                        personalized plan.
                      </p>
                      <p className="thm-btn slider-one__btn ">
                        Call Now +91 9266829800
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="item"
          style={{
            backgroundImage: "url(assets/images/main-slider/slider3.jpg)",
          }}
        >
          <div className="slider-one__item">
            <div className="container">
              <div className="row justify-content-end">
                <div className="col-lg-12 d-flex justify-content-end">
                  <div className="slider-one__content">
                    <div className="slider-one__content-inner">
                      <h3>
                        Swarnim Ayu Health <br></br> is Wealth.
                      </h3>
                      <p>
                        Good health saves money, while poor health can be
                        costly. Reduce expenses and save time with Swarnim Ayu.
                      </p>
                      <p className="thm-btn slider-one__btn ">
                        Call Now +91 9266829800
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </OwlCarousel>
    </section>
  );
};

export default SliderOne;
