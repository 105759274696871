import React, { useEffect, useState } from "react";
import PageBanner from "../Components/PageBanner";
import axios from "axios";

function CustomerGallery() {
  const [subCategories, setSubCategories] = useState([]);
  const [videos, setVideos] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState(0);

  // Fetch the subcategories when the component mounts
  useEffect(() => {
    axios
      .post(
        "https://api.swarnimayu.com/api/CarGiverMobile/GallerySubCategoryList"
      )
      .then((response) => {
        if (response.data.status) {
          setSubCategories(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching subcategories:", error);
      });
  }, []);

  // Fetch videos when the selected subcategory changes
  useEffect(() => {
    axios
      .post(
        `https://api.swarnimayu.com/api/CarGiverMobile/GallerySubCategoryListIdWise?subcatid=${selectedSubCategory}`
      )
      .then((response) => {
        if (response.data.status) {
          setVideos(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching videos:", error);
      });
  }, [selectedSubCategory]);

  // Handle dropdown change
  const handleSubCategoryChange = (event) => {
    setSelectedSubCategory(event.target.value);
  };

  // Extract YouTube video ID from the URL
  const extractVideoId = (url) => {
    const urlParts = url.split("v=");
    return urlParts[1];
  };

  return (
    <>
      <PageBanner
        name=" Customer Gallery"
        image="assets/images/services/legalservice/banner.jpg"
      />

      <section className="contact-info-one">
        <div className="container">
          <div className="block-title text-center">
            <select
              onChange={handleSubCategoryChange}
              value={selectedSubCategory}
              className="form-control"
            >
              {subCategories.map((subCategory) => (
                <option key={subCategory.id} value={subCategory.id}>
                  {subCategory.cname}
                </option>
              ))}
            </select>
          </div>
          <div className="row">
            {videos.map((video) => (
              <div key={video.id} className="col-md-6">
                <h3>{video.tittle}</h3>
                <iframe
                  width="100%"
                  height="315"
                  src={`https://www.youtube.com/embed/${extractVideoId(
                    video.cname
                  )}`}
                  title={video.tittle}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default CustomerGallery;
