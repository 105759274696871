import React from "react";
import BestHomeCare from "../Components/BestHomeCare";
import PageBanner from "../Components/PageBanner";
import Servicelist from "../Components/Servicelist";
import Callnow from "../Components/Callnow";
function Insurance() {
  return (
    <>
      <PageBanner
        name="Insurance Services"
        image="assets/images/services/Insuranace/Banner.jpg"
      />
      {/* <BestHomeCare /> */}
      <section className="service-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <Servicelist />
            </div>
            <div className="col-lg-8">
              <div className="service-details__main">
                <div className="service-details__image">
                  <img
                    src="assets/images/services/Insuranace/Banner.jpg"
                    className="img-fluid"
                    alt=""
                  ></img>
                </div>
                <div className="service-details__content">
                  <h3>Insurance Services</h3>
                  <p>
                    We are registered insurance advisors approved by IRDEA – to
                    assist in any type of insurance – except LIFE INSURANCE.
                  </p>
                  We will do an investigation and offer insurances in the
                  following areas <br></br>
                  <p>
                    <h4>1. Travel Insurance</h4>
                    This will be loss of luggage, accident, insurance.
                  </p>
                  <p>
                    <h4>2. Travel Health Insurance</h4>
                    Health insurance while travelling abroad.
                  </p>
                  <p>
                    <h4>3. Normal Health Insurance </h4>
                    While in India.
                  </p>
                  <p>
                    <h4>4. Property insurance </h4>
                    Risk like fire, theft , floow earth quake etc – can be
                    covered in this insurance.
                  </p>
                  <p>
                    <h4>5. Motor Car insurance. </h4>* Any other risk, that you
                    may feel.. we can talk and do the same.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Callnow />
    </>
  );
}

export default Insurance;
