import React from "react";
import BestHomeCare from "../Components/BestHomeCare";
import PageBanner from "../Components/PageBanner";
import Servicelist from "../Components/Servicelist";
import Callnow from "../Components/Callnow";
function Investment() {
  return (
    <>
      <PageBanner
        name="Investment Services"
        image="assets/images/services/Investment/Banner.jpg"
      />
      <section className="service-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <Servicelist />
            </div>
            <div className="col-lg-8">
              <div className="service-details__main">
                <div className="service-details__image">
                  <img
                    src="assets/images/services/Investment/Main.jpg"
                    className="img-fluid"
                    alt=""
                  ></img>
                </div>
                <div className="service-details__content">
                  <h3>Investment services</h3>
                  <p>
                    The elderly need a lower risk and an adequately return
                    generating deployment of their funds. Swarnim Ayu offers an
                    Investment consultation service which will understand your
                    requirements and different needs and wishes. We will then
                    advice a suitable Mutual Fund portfolio.{" "}
                  </p>
                  <p>
                    This portfolio will be in your name, and in your full
                    control. At no point our advisor can do any thing on this
                    without your active concurrence.
                  </p>
                  <p>
                    The advisor is SK Agrawal. He is a B Tech from IIT Kanpur,
                    age 67. He is an SEBI Approved Mutual Fund Distributer. His
                    Unique number is ARN-256629. He has over 100 investors
                    associated with him.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Callnow />
    </>
  );
}

export default Investment;
