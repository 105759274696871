import React from "react";
import { Link } from "react-router-dom";
function Footers() {
  return (
    <footer className="site-footer">
      <div className=" site-footer__main">
        <div className="container">
        <div className="row mb-5">
        <div className="col-xl-4 col-lg-6 col-md-6">
        <div className="site-footer__logo">
            <a href="index.html">
              <img
                src="assets/images/logo.png"
                width="219"
                alt=""
              ></img>
            </a>
          </div>
        </div>
        <div className="col-xl-4 col-lg-6 col-md-6">
        
        </div>
        <div className="col-xl-4 col-lg-6 col-md-6 ">
        <div className="site-footer__social ml-5">
            <a href="#">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="#">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="#">
              <i className="fa fa-rss"></i>
            </a>
            <a href="#">
              <i className="fab fa-google-plus-g"></i>
            </a>
            <a href="#">
              <i className="fab fa-vimeo-v"></i>
            </a>
          </div>
        </div>
          
        </div>
       
          <div className="row">
            <div className="col-xl-5 col-lg-6 col-md-6">
              <div className="footer-widget footer-widget__about">
                <h3 className="footer-widget__title">About</h3>
                <p>
                Swarnim Ayu was created to support seniors as rising life expectancy and smaller family sizes often leave the elderly to care for themselves, providing them with essential assistance and care.
                </p>
               
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="footer-widget footer-widget__links ">
                <h3 className="footer-widget__title">Services</h3>
                <ul className="list-unstyled footer-widget__links-list">                
                <li> <h2> <Link to="/Care-giver-medical">Care Giver Medical</Link></h2></li>
                <li> <h2> <Link to="/Care-giver-non-medical">Care Giver non Medical</Link></h2></li>
                <li> <h2> <Link to="/old-age-home">Old age home Residential</Link></h2></li>
                
                 
                </ul>
              </div>
            </div>
           
            <div className="col-xl-3 col-lg-6 col-md-6">
              <div className="footer-widget footer-widget__contact">
                <h3 className="footer-widget__title">Contacts</h3>
                <p>
                192, Sector 56, Gurgaon 122011
                </p>
                <p>
                  <a href="tel:+91 9811799499">+91 9266829800</a> 
                </p>
                <p>
                  <a href="mailto:info@oberlin.com">care@Swarnim Ayu.com</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="site-footer__bottom">
        <div className="container">
          <p>
           Swarnim Ayu <i className="far fa-copyright"></i> 2024 All Right Reserved
          </p>
          <ul className="list-unstyled site-footer__menu">
          <li>
            <Link to="/Paymentandrefundpolicy" className=""> Payment, Refund & Cancellation Policies </Link>
            </li>
            <li>
            <Link to="/termsandconditions" className=""> Terms and conditions </Link>
              {/* <a href="#">Terms of Service</a> */}
            </li>
            <li>
            <Link to="/privacyandpolicy" className=""> Privacy Policy </Link>
            </li>            
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footers;
